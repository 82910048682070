<template>
  <div class="container">
    <div id="contentPage">
      <div id="pageNotFoundDiv">
        <a href="/" title="Nazad na naslovnu stranicu">
          <img src="./../assets/images/404.svg">
        </a>

        <p v-if="lang === 'lat'">Stranica koju ste tražili nije dostupna.<br>Izvinjavamo se zbog neugodnosti.</p>
        <p v-else>Страница коју сте тражили није доступна.<br>Извињавамо се због неугодности.</p>
      </div>
    </div>
  </div>
</template>

<script>
  import LangMixin from "../mixins/LangMixin";

  export default {
    mixins: [
        LangMixin
    ],

    mounted() {
      this.$emit('pageLoaded');
    }
  }
</script>